import React from "react";
import {
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	FormHelperText,
} from "@mui/material";

export default function FormSelect({
	error,
	label,
	onChange,
	data,
	helper,
	value,
	defaultValue,
}) {
	const roles = ["ROLE_ADMIN", "ROLE_USER"];

	return (
		<FormControl error={error} fullWidth sx={{ my: 1 }}>
			<InputLabel>{label}</InputLabel>
			<Select
				defaultValue={defaultValue}
				labelId={`${label}-Selection`}
				id={`${label}-Selection`}
				value={value}
				label={label}
				onChange={onChange}
			>
				{label === "Roles"
					? roles.map((item, index) => (
						<MenuItem key={index} value={item}>
							{item}
						</MenuItem>
					))
					: data?.map((item) => (
						<MenuItem key={item?.id} value={item?.id}>
							{label === "Project" &&
								`${item?.code} - ${item?.project_name} - ${item.status}`}
						</MenuItem>
					))}
			</Select>
			<FormHelperText>{helper}</FormHelperText>
		</FormControl>
	);
}
