import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

export default function LoadingTable() {
  return (
    <TableContainer component={Paper} sx={{ py: 2 }}>
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
      <Skeleton width={"100vw"} height={100} />
    </TableContainer>
  );
}
