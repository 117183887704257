import * as React from "react";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import Image from "mui-image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../api/authApi";
import { useNavigate } from "react-router-dom";
import routes from "../Services/routes";
import { useAppContext } from "../contexts/AppContext";
import CircularProgress from "@mui/material/CircularProgress";
import background from "../assets/images/1bis.jpg";
import logo from "../assets/images/logoretina.png";
import Copyright from "../Components/Copyright";

const theme = createTheme();

export default function LoginScreen() {
	const navigate = useNavigate();
	const { setIsAutenticated, setIsAdmin, getUser } = useAppContext();

	const login = async (values) => {
		try {
			const response = await authApi.login(values);
			if ((response.status = 200)) {
				if (response.isAdmin) {
					setIsAutenticated(true);
					navigate(routes.reports);
					setIsAdmin(true);
				} else {
					setIsAutenticated(true);
					navigate(routes.reports);
					setIsAdmin(false);
				}
			}
			try {
				await getUser(response.userId);
			} catch (error) {
				console.log("ERRORR GET USER", response)
			}

		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORLOGIN", error);
			toast.error("Bad Credentials", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}
	};
	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
		validationSchema: Yup.object({
			username: Yup.string()
				.email("Must be a valid email")
				.max(255)
				.required("Email is required"),
			password: Yup.string().max(255).required("Password is required"),
		}),
		onSubmit: (values) => {
			login(values);
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<ToastContainer />

			<Grid container component="main" sx={{ height: "100vh" }}>
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: `url(${background})`,
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light"
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Image
							sx={{ p: 5, maxWidth: "400px" }}
							src={logo}
						/>
						<form onSubmit={formik.handleSubmit}>
							<TextField
								error={Boolean(
									formik.touched.username && formik.errors.username
								)}
								fullWidth
								helperText={formik.touched.username && formik.errors.username}
								label="Email Address"
								margin="normal"
								name="email"
								onBlur={formik.handleBlur("username")}
								onChange={formik.handleChange("username")}
								type="email"
								value={formik.values.username}
								variant="outlined"
								autoComplete="username"

							/>
							<TextField
								error={Boolean(
									formik.touched.password && formik.errors.password
								)}
								fullWidth
								helperText={formik.touched.password && formik.errors.password}
								label="Password"
								margin="normal"
								name="password"
								onBlur={formik.handleBlur("password")}
								onChange={formik.handleChange("password")}
								type="password"
								value={formik.values.password}
								variant="outlined"
								autoComplete="current-password"
							/>
							<Box sx={{ py: 2, justifyContent: "center" }}>
								{!formik.isSubmitting ? (
									<Button
										color="primary"
										disabled={formik.isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Sign In Now
									</Button>
								) : (
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<CircularProgress />
									</Box>
								)}
							</Box>
						</form>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
