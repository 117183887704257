import { Box, useMediaQuery, Avatar } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Chip from "@mui/material/Chip";
import { useAppContext } from "../contexts/AppContext";
import { useLocation } from "react-router-dom";

export default function SearchBar({
	onChange,
	handleChipsFilter,
	placeholder,
	handleUsersFilter,
	handleFreelancersFilter,
	setSelectedFilter,
	selectedFilter,
	getProcessedReports,
	getUnProcessedReports,
	getBisReports,
	getRocheReports
}) {
	const { originalReports, setSearch, search, users, isAdmin, setReports, user } = useAppContext();
	const location = useLocation();
	const smartphone = useMediaQuery("(max-width:600px)");

	const handleAllrequest = async () => {
		setSelectedFilter(null)
		setReports(originalReports)
		setSelectedFilter("ALL")
	}

	const handleMinerequest = async () => {
		setSelectedFilter(null)
		setSelectedFilter("MINE")
		setReports(originalReports?.filter(r => {
			return r.user.some(us => us.id === user['id']);
		}))
	}
	return (
		<Box
			sx={{
				display: "flex",
				width: "80%",
				ml: 4,
				mt: 4,
				flexDirection: "column",
			}}
		>
			<Input
				startAdornment={
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				}
				onChange={onChange}
				id="filled-basic"
				placeholder={placeholder}
				value={search}
			/>
			<Box
				sx={{
					display: "flex",
					my: 1,
				}}
			>
				<Chip sx={{ m: 0.5 }} label="❌" onClick={() => setSearch("")} />
				<Box sx={{ display: "flex", flexWrap: "wrap" }}>
					{location.pathname === "/reports" && isAdmin && (users.map(u => <Chip variant={selectedFilter === u.id ? "filled" : "outlined"} color={selectedFilter === u.id ? "warning" : "default"} avatar={u?.avatar && <Avatar alt="Avatar" src={`../assets/images/${u?.avatar}`} />}
						onClick={() => handleUsersFilter(u.id)} sx={{ m: 0.5 }} label={u.tag} key={u.id} />))}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "ALL" ? "filled" : "outlined"} color={selectedFilter === "ALL" ? "warning" : "default"} label="All" onClick={() => handleAllrequest()} />}
					{location.pathname === "/reports" && !isAdmin && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "MINE" ? "filled" : "outlined"} color={selectedFilter === "MINE" ? "warning" : "default"} label="Mine" onClick={() => handleMinerequest()} />}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "FREELANCER" ? "filled" : "outlined"} color={selectedFilter === "FREELANCER" ? "warning" : "default"} label="Freelance" onClick={() => handleFreelancersFilter()} />}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "PROCESSED" ? "filled" : "outlined"} color={selectedFilter === "PROCESSED" ? "warning" : "default"} label="Processed" onClick={() => getProcessedReports()} />}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "UNPROCESSED" ? "filled" : "outlined"} color={selectedFilter === "UNPROCESSED" ? "warning" : "default"} label="Unprocessed" onClick={() => getUnProcessedReports()} />}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "BIS" ? "filled" : "outlined"} color={selectedFilter === "BIS" ? "warning" : "default"} label="BIS" onClick={() => getBisReports()} />}
					{location.pathname === "/reports" && <Chip sx={{ m: 0.5 }} variant={selectedFilter === "ROCHE" ? "filled" : "outlined"} color={selectedFilter === "ROCHE" ? "warning" : "default"} label="ROCHE" onClick={() => getRocheReports()} />}
					{location.pathname === "/projects" && (
						<Box sx={{ mx: 1 }}>
							<Chip
								color={search.toLowerCase() === "pending" ? "primary" : "default"}
								label={smartphone ? "⏸️" : "⏸️ Pending"}
								onClick={() => handleChipsFilter("Pending")}
							/>
							<Chip
								sx={{ mx: 0.5 }}
								color={
									search.toLowerCase() === "in process" ? "primary" : "default"
								}
								label={smartphone ? "🚧" : "🚧 In Process"}
								onClick={() => handleChipsFilter("In Process")}
							/>
							<Chip
								sx={{ mx: 0.5 }}
								color={search.toLowerCase() === "done" ? "primary" : "default"}
								label={smartphone ? "✔️" : "✔️ Done"}
								onClick={() => handleChipsFilter("Done")}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}
