import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteIconButton({ handleDelete }) {
  return (
    <IconButton
      sx={{ color: "red" }}
      aria-label="delete"
      onClick={handleDelete}
    >
      <DeleteIcon />
    </IconButton>
  );
}
