import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectDetailsTable from "../../Components/ProjectsComponents/ProjectDetailsTable";
import projectsApi from "../../api/projectsApi";
import ProjectDetailsReportsTable from "../../Components/ProjectsComponents/ProjectDetailsReportsTable";
import DeleteAlert from "../../Components/DialogAlert/DeleteAlert";
import Typography from '@mui/material/Typography'
import routes from "../../Services/routes";

export default function ProjectDetailsScreen() {
	const navigate = useNavigate();
	const { state: project } = useLocation();
	const [isAlertOpen, setIsAlertOpen] = useState(false);

	const handleClickOpen = () => {
		setIsAlertOpen(true);
	};

	const handleClose = () => {
		setIsAlertOpen(false);
	};

	const closeAndNotify = (message) => {
		toast(message, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "dark",
		});
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	const handleProjectDelete = async (id) => {
		setIsAlertOpen(false);
		try {
			await projectsApi.deleteItem(id);
		} catch (error) {
			toast.error("Something went wrong, try again.", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			console.log("EROOR DELETE PROJECT", error);
		} finally {
			closeAndNotify("Project Deleted");
		}
	};

	return (
		<>
			<ToastContainer />
			<Box sx={{ flexGrow: 1, mt: 5, mx: 2 }}>
				<ButtonGroup
					sx={{ mb: 2 }}
					variant="contained"
					aria-label="outlined primary button group"
				>
					<Button onClick={() => navigate(-1)}>
						<ArrowBackIosIcon />
					</Button>
					<Button onClick={() => navigate(routes.editProject, { state: project })}>
						<EditIcon />
					</Button>
					<Button onClick={() => handleClickOpen()}>
						<DeleteIcon />
					</Button>
				</ButtonGroup>
				<ProjectDetailsTable project={project} />
				{project?.project_reports.length > 0 ? <ProjectDetailsReportsTable project={project} /> : <Typography variant="caption" color="initial">⚠️ No reports yet</Typography>}
			</Box>

			<DeleteAlert
				open={isAlertOpen}
				title="Delete Project"
				data={project}
				handleDelete={() => handleProjectDelete(project.id)}
				clickClose={handleClose}
			/>
		</>
	);
}
