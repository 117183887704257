const routes = {
	login: "/",
	// ______________________________
	reports: "/reports",
	reportDetails: "/report-details",
	newReport: "/new-report",
	editReport: "/edit-report",
	help: "/help",
	// ______________________________
	projects: "/projects",
	projectDetails: "/project-details",
	newProject: "/new-project",
	editProject: "/edit-project",
	// ______________________________
	users: "/users",
	userProfil: "/user-profil",
	newUser: "/new-user",
	// ______________________________
	dashboard: "/dashboard",
	// ______________________________
	support: "/support",
};

export default routes