import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormTitle from "../../Components/FomsComponents/FormTitle";
import FormButton from "../../Components/FomsComponents/FormButton";
import FormTextField from "../../Components/FomsComponents/FormTextField";
import projectsApi from "../../api/projectsApi";
import routes from "../../Services/routes";

export default function NewProjectScreen() {
	const location = useLocation()
	const navigate = useNavigate();
	const addProject = async (values) => {
		try {
			const response = await projectsApi.createProject(values);
			console.log(response);
			toast("Project Created", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setTimeout(() => {
				location?.state?.from === routes.newReport ? navigate(routes.newReport, { state: { from: routes.newProject, createdProject: response.data } }) :
					navigate(routes.projects);
			}, 2000);
		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORPOSTPROJ", error.response);
			toast.error(error.response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}
	};
	const formik = useFormik({
		initialValues: {
			code: "",
			projectName: "",
		},
		validationSchema: Yup.object().shape({
			code: Yup.string().required("Code is Required"),
			projectName: Yup.string().required("Project Name is required").nullable(),
		}),

		onSubmit: (values) => {
			addProject(values);
		},
	});



	return (
		<>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					alignItems: "center",
					display: "flex",
					flexGrow: 1,
					minHeight: "100%",
				}}
			>
				<Container maxWidth="sm">
					<form onSubmit={formik.handleSubmit}>
						<FormTitle title={"New Project"} action={() => navigate(-1)} />

						<FormTextField
							error={Boolean(formik.touched.code && formik.errors.code)}
							helperText={formik.touched.code && formik.errors.code}
							label="Project code"
							onBlur={formik.handleBlur("code")}
							onChange={formik.handleChange("code")}
							value={formik.values.code}
						/>

						<FormTextField
							error={Boolean(formik.touched.projectName && formik.errors.projectName)}
							helperText={formik.touched.projectName && formik.errors.projectName}
							label="Project name"
							onBlur={formik.handleBlur("projectName")}
							onChange={formik.handleChange("projectName")}
							value={formik.values.projectName}
						/>


						<FormButton
							text={"Create Project"}
							disabled={formik.isSubmitting}
						/>
					</form>
				</Container>
			</Box>
		</>
	);
}
