import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormSelect from "../../Components/FomsComponents/FormSelect";
import FormTitle from "../../Components/FomsComponents/FormTitle";
import FormButton from "../../Components/FomsComponents/FormButton";
import FormTextField from "../../Components/FomsComponents/FormTextField";
import userApi from "../../api/userApi";
import routes from "../../Services/routes";

export default function NewUserScreen() {
	const navigate = useNavigate();

	const addUser = async (values) => {
		try {
			await userApi.createUser(values);
			toast("User Created", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setTimeout(() => {
				navigate(routes.users);
			}, 2000);
		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORPOSTUSER", error);
			toast.error(error?.response?.data?.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}
	};
	const formik = useFormik({
		initialValues: {
			firstname: "",
			lastname: "",
			email: "",
			phone: "",
			password: "",
			roles: "",
			tag: "",
		},
		validationSchema: Yup.object().shape({
			firstname: Yup.string().required("Firstname is Required"),
			lastname: Yup.string().required("Lastname is Required"),
			email: Yup.string().email().required("Email is required").nullable(),
			phone: Yup.string().nullable(),
			password: Yup.string().required("Password is required"),
			roles: Yup.string().required("Role is required"),
			tag: Yup.string().required("Tag is required"),
		}),

		onSubmit: (values) => {
			addUser(values);
		},
	});

	return (
		<>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					alignItems: "center",
					display: "flex",
					flexGrow: 1,
					minHeight: "100%",
				}}
			>
				<Container maxWidth="sm">
					<form onSubmit={formik.handleSubmit}>
						<FormTitle title={"New User"} action={() => navigate(-1)} />

						<FormTextField
							error={Boolean(formik.touched.email && formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
							label="Email"
							onBlur={formik.handleBlur("email")}
							onChange={formik.handleChange("email")}
							value={formik.values.email}
						/>

						<FormTextField
							error={Boolean(formik.touched.firstname && formik.errors.firstname)}
							helperText={formik.touched.firstname && formik.errors.firstname}
							label="Firstname"
							onBlur={formik.handleBlur("firstname")}
							onChange={formik.handleChange("firstname")}
							value={formik.values.firstname}
						/>

						<FormTextField
							error={Boolean(formik.touched.lastname && formik.errors.lastname)}
							helperText={formik.touched.lastname && formik.errors.lastname}
							label="Lastname"
							onBlur={formik.handleBlur("lastname")}
							onChange={formik.handleChange("lastname")}
							value={formik.values.lastname}
						/>

						<FormTextField
							error={Boolean(formik.touched.password && formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
							label="Password"
							onBlur={formik.handleBlur("password")}
							onChange={formik.handleChange("password")}
							value={formik.values.password}
							type="password"
						/>

						<FormTextField
							error={Boolean(formik.touched.phone && formik.errors.phone)}
							helperText={formik.touched.phone && formik.errors.phone}
							label="Phone"
							onBlur={formik.handleBlur("phone")}
							onChange={formik.handleChange("phone")}
							value={formik.values.phone}
						/>

						<FormTextField
							error={Boolean(formik.touched.tag && formik.errors.tag)}
							helperText={formik.touched.tag && formik.errors.tag}
							label="Tag"
							onBlur={formik.handleBlur("tag")}
							onChange={formik.handleChange("tag")}
							value={formik.values.tag}
						/>

						<FormSelect
							error={Boolean(formik.touched.roles && formik.errors.roles)}
							label="Roles"
							value={formik.values.roles}
							onChange={formik.handleChange("roles")}
							helper={formik.touched.roles && formik.errors.roles}
						/>

						<FormButton text={"Create User"} disabled={formik.isSubmitting} />
					</form>
				</Container>
			</Box>
		</>
	);
}
