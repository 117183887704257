import React from "react";
import {
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	FormHelperText,
} from "@mui/material";

export default function FormStatusSelect({
	error,
	label,
	onChange,
	helper,
	value,
	defaultValue,
}) {
	return (
		<FormControl error={error} fullWidth sx={{ my: 1 }}>
			<InputLabel id="locations-Selection">{label}</InputLabel>
			<Select
				defaultValue={defaultValue}
				labelId={`${label}-Selection`}
				id={`${label}-Selection`}
				value={value}
				label={label}
				onChange={onChange}
			>
				<MenuItem value={"In Process"}>In Process</MenuItem>
				<MenuItem value={"Done"}>Done</MenuItem>
			</Select>
			<FormHelperText>{helper}</FormHelperText>
		</FormControl>
	);
}
