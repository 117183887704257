import React, { createContext, useState, useContext, useEffect } from "react";
import apiClient from "../api/client";
import reportsApi from "../api/reportsApi";
import userApi from "../api/userApi";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import projectsApi from "../api/projectsApi";
import routes from "../Services/routes";

const Context = createContext();
const key = "authToken";
export default function AppContext({ children }) {
	const navigate = useNavigate();
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [showBreakInput, setShowBreakInput] = useState(false);
	const [showPrepInput, setShowPrepInput,] = useState(false);
	const [showPostProdInput, setShowPostProdInput] = useState(false);
	const [isAutenticated, setIsAutenticated] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [user, setUser] = useState();
	const [users, setUsers] = useState([]);
	const [freelancers, setFreelancers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const [originalReports, setOriginalReports] = useState([]);
	const [report, setReport] = useState({});
	const [projects, setProjects] = useState([]);
	const [search, setSearch] = useState("");
	const [doneStatus, setDoneStatus] = useState(false);

	const getReports = async () => {
		setIsLoading(true)
		try {
			const response = await reportsApi.findAll();
			setReports(response);
			setOriginalReports(response)
			setIsLoading(false)
		} catch (error) {
			console.log("ERROORGETREPORTS", error);
		}
	};

	const getMyReports = async () => {
		setIsLoading(true)
		const token = window.localStorage.getItem(key);
		const decoded = jwt_decode(token);
		try {
			const response = await userApi.getConnectedUserReports(decoded.id);
			setReports(response.data);
			setIsLoading(false)
		} catch (error) {
			console.log("ERROORGETMYREPORTS", error);
		}
	};


	const getUser = async () => {
		const token = window.localStorage.getItem(key);
		const decoded = jwt_decode(token);
		try {
			const response = await userApi.getUser(decoded.id);
			setUser(response);
		} catch (error) {
			console.log("ERROORGETUSER", error);
		}
	};


	const getUsers = async () => {
		try {
			const response = await userApi.getUsers();
			setUsers(response);
		} catch (error) {
			console.log("ERROORGETUSERS", error);
		}
	};
	const getFreelancers = async () => {
		try {
			const response = await userApi.getFreelancers();
			setFreelancers(response);
		} catch (error) {
			console.log("ERROORGETFREELANCERS", error);
		}
	};


	const getProjects = async () => {
		try {
			const res = await projectsApi.findAll();
			setProjects(res);
		} catch (error) {
			console.log("EROOOGETPROJ", error.response);
		}
	};


	const setup = () => {
		try {
			const token = window.localStorage.getItem(key);
			if (token) {
				const decoded = jwt_decode(token);
				if (decoded.exp * 1000 > new Date().getTime()) {
					const isAdmin = decoded.roles.includes("ROLE_ADMIN");
					setIsAdmin(isAdmin);
					setIsAutenticated(true);
					apiClient.interceptors.request.use(function (config) {
						const token = window.localStorage.getItem(key);
						config.headers.Authorization = token ? `Bearer ${token}` : "";
						return config;
					});
					navigate(routes.reports);
				}
			}
		} catch (error) {
			navigate(routes.login);
			console.log("ERRROORSETUP", error)
		}
	};

	const logout = () => {
		window.localStorage.removeItem(key);
		apiClient.interceptors.request.use(function (config) {
			delete config.headers.Authorization;
			return config;
		});
		setIsAutenticated(false);
		setUser(null);
		setIsAdmin(false);
		navigate(routes.login);
	};


	useEffect(() => {
		setup();
	}, []);

	return (
		<Context.Provider
			value={{
				getFreelancers, freelancers,
				report, setReport,
				logout,
				setup,
				user,
				setUser,
				getUser,
				reports,
				setReports,
				isAutenticated,
				setIsAutenticated,
				isLoading,
				setIsLoading,
				projects,
				getProjects,
				isAdmin,
				setIsAdmin,
				getReports,
				isAlertOpen,
				setIsAlertOpen,
				page,
				setPage,
				search,
				setSearch,
				showBreakInput,
				setShowBreakInput,
				showPostProdInput,
				setShowPostProdInput,
				getUsers,
				users,
				setUsers,
				setProjects, getMyReports,
				showPrepInput,
				setShowPrepInput,
				doneStatus,
				setDoneStatus,
				originalReports, setOriginalReports
			}}
		>
			{children}
		</Context.Provider>
	);
}

export const useAppContext = () => useContext(Context);
