import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';


export default function ReportDetailsTable({
	report,
	user,
	isAdmin,
	reportUsers,
	loadingSpinner,
	handleUserDelete,
	selected,
	setSelected,
	users,
	freelancers,
	handleAddUser,
	open, setOpen,
	openFreelancerSelect,
	SetOpenFreelancerSelect,
	selectedFreelancer,
	setSelectedFreelancer,
	reportFreelancers,
	handleAddFreelancer,
	handleFreelancerDelete
}) {
	const date = moment(report?.date).format("MMM Do YY");
	const getIn = report?.getin != null && moment(report?.getin && report?.getin).format("HH:mm");
	const start = moment(report?.start && report?.start).format("HH:mm");
	const end = moment(report?.end && report?.end).format("HH:mm");
	const duration = getTimeFromMins(report?.duration);
	const eventDuration = getTimeFromMins(report?.event_duration);
	const breakDuration = getTimeFromMins(report?.break_duration);
	const prepDuration = getTimeFromMins(report?.prep_duration);
	const postProdDuration = getTimeFromMins(report?.post_prod_duration);
	const otherUsers = reportUsers?.filter(u => u?.id !== user?.id)
	const mainUser = reportUsers?.find(u => u?.id === user?.id)

	function getTimeFromMins(mins) {
		if (mins >= 24 * 60 || mins < 0) {
			throw new RangeError(
				"Valid input should be greater than or equal to 0 and less than 1440."
			);
		}
		let h = (mins / 60) | 0,
			m = mins % 60 | 0;
		return moment.utc().hours(h).minutes(m).format("HH:mm");
	}

	const handleChange = (event) => {
		setSelected(event.target.value)
	}

	const handleFreelancerChange = (event) => {
		setSelectedFreelancer(event.target.value)

	}

	return (
		<Fade in={true} timeout={1000}>
			<TableContainer component={Paper} sx={{ py: 2, alignSelf: "center" }}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell variant="head">Event Date</TableCell>
							<TableCell>{date}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">User (s)</TableCell>
							<TableCell>

								{
									mainUser &&
									<Chip
										avatar={mainUser?.avatar ? <Avatar alt={mainUser?.tag} src={`../assets/images/${mainUser?.avatar}`} /> : undefined}
										sx={{ m: 0.5 }} label={mainUser?.tag} />
								}

								{
									mainUser || isAdmin
										?
										otherUsers?.map(u => loadingSpinner.id === u.id && loadingSpinner.loading === true
											?
											<CircularProgress sx={{ mt: 1, mx: 1 }} size={10} key={u.id} />
											:
											<Chip avatar={u?.avatar ?
												<Avatar alt={u?.tag} src={`../../assets/images/${u?.avatar}`} /> : undefined} onDelete={() => handleUserDelete(u)} key={u?.id} label={u?.tag} sx={{ m: 0.5 }} />)
										:
										otherUsers?.map(u => <Chip avatar={u?.avatar && <Avatar alt={u?.tag} src={`../../assets/images/${u?.avatar}`} />}
											sx={{ m: 0.5 }} key={u?.id} label={u?.tag} />)
								}

								<Chip
									onClick={() => setOpen(true)}
									variant="outlined" sx={{ m: 0.5 }} label={"➕👤"} />

								<Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)}>
									<DialogTitle>Add a Teammate</DialogTitle>
									<DialogContent>
										<Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
											<FormControl sx={{ m: 1, minWidth: 120 }}>
												<InputLabel id="user-select">User</InputLabel>
												<Select
													labelId="user-select"
													id="users-select"
													value={selected}
													onChange={handleChange}
													input={<OutlinedInput label="User" />}
												>
													{
														users?.map(user => <MenuItem disabled={reportUsers.find(ru => ru.id === user.id) ? true : false} key={user.id} value={user.id}>
															<Chip avatar={user?.avatar && <Avatar alt="Avatar" src={`../../assets/images/${user?.avatar}`} />}
																sx={{ m: 0.5 }} key={user?.id} label={user?.tag} /></MenuItem>)
													}
												</Select>
											</FormControl>
										</Box>
									</DialogContent>
									<DialogActions>
										<Button disabled={selected ? false : true} onClick={handleAddUser}>Ok</Button>
									</DialogActions>
								</Dialog>
							</TableCell>
						</TableRow>
						{/* __________________ */}
						<TableRow>
							<TableCell variant="head">Freelancers (s)</TableCell>
							<TableCell>
								{
									reportFreelancers?.length > 0 ?
										reportFreelancers?.map(f =>
											loadingSpinner.id === f.id && loadingSpinner.loading === true
												?
												<CircularProgress sx={{ mt: 1, mx: 1 }} size={10} key={f.id} />
												:
												<Chip
													onDelete={() => handleFreelancerDelete(f)}
													size="small"
													color="secondary"
													sx={{ m: 0.5 }}
													variant="filled"
													key={f.id}
													label={f?.firtsname?.charAt(0) + f?.lastname?.charAt(0)} />)
										:
										"❌"
								}
								<Chip
									onClick={() => SetOpenFreelancerSelect(true)}
									variant="outlined" sx={{ m: 0.5 }} label={"➕👤"} />

								<Dialog disableEscapeKeyDown open={openFreelancerSelect} onClose={() => SetOpenFreelancerSelect(false)}>
									<DialogTitle>Add a Freelancer</DialogTitle>
									<DialogContent>
										<Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
											<FormControl sx={{ m: 1, minWidth: 120 }}>
												<InputLabel id="freelancer-select">Freelancers</InputLabel>
												<Select
													labelId="freelancer-select"
													id="freelancers-select"
													value={selectedFreelancer}
													onChange={handleFreelancerChange}
													input={<OutlinedInput label="Freelancer" />}
												>
													{
														freelancers?.map(freelancer => <MenuItem disabled={report?.freelancers?.find(f => f.id === freelancer.id) ? true : false} key={freelancer.id} value={freelancer.id}>
															<Chip sx={{ m: 0.5 }} key={freelancer?.id} label={freelancer?.lastname} /></MenuItem>)
													}
												</Select>
											</FormControl>
										</Box>
									</DialogContent>
									<DialogActions>
										<Button disabled={selectedFreelancer ? false : true} onClick={handleAddFreelancer}>Ok</Button>
									</DialogActions>
								</Dialog>



							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Project Code</TableCell>
							<TableCell>{report?.project?.code || "No linked Project"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Project Name</TableCell>
							<TableCell>{report?.project?.project_name || "No linked Project"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Customer</TableCell>
							<TableCell>{report?.customer || "No linked Customer"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Contact</TableCell>
							<TableCell>{report?.contact || "No linked Contact"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Location</TableCell>
							<TableCell>{report?.location || "No linked Location"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Get-In</TableCell>
							<TableCell>{getIn ? getIn : "No Get-in"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Start</TableCell>
							<TableCell>{start}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">End</TableCell>
							<TableCell>{end}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Expenses (chf)</TableCell>
							<TableCell> {report?.expense || "❌ 💵"}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Processed</TableCell>
							<TableCell>{report?.processed === true ? "✔️" : "❌"}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell variant="head">Preparation Duration (h)</TableCell>
							<TableCell>{prepDuration !== "00:00" ? prepDuration : "No Prep-Phase"}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell variant="head">Duration (h)</TableCell>
							<TableCell>{duration}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Event Duration (h)</TableCell>
							<TableCell>{eventDuration}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Break Duration (h)</TableCell>
							<TableCell>{breakDuration !== "00:00" ? breakDuration : "No break"}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Post-Prod Duration (h)</TableCell>
							<TableCell>{postProdDuration !== "00:00" ? postProdDuration : "No Post-Production"}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Remarks</TableCell>
							<TableCell>{report?.remarks ? report?.remarks : "No Remarks"}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	);
}



