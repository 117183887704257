import React from "react";
import { Box, Button } from "@mui/material";

export default function FormButton({ disabled, text }) {
  return (
    <Box sx={{ py: 2 }}>
      <Button
        color="primary"
        disabled={disabled}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {text}
      </Button>
    </Box>
  );
}
