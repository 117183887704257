import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, ListItemIcon } from '@mui/material';
import fileDownloadApi from '../../api/fileDownloadApi';
import { downloadsAssets } from './../../Services/downloads';

export default function BasicAccordion() {
	return (
		<div>
			{downloadsAssets?.map(asset => <Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2a-content"
					id="panel2a-header"
				>
					<Typography>{asset.mainTitle}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List dense={true} disablePadding>
						<ListItem sx={{ display: 'flex', flexWrap: 'wrap' }}>
							{asset.files.map(file => <ListItemButton
								onClick={() => fileDownloadApi.getFile(file.fileName)}
							>
								<ListItemIcon>
									{file.icon}
								</ListItemIcon>
								<Typography sx={{ fontSize: '0.75rem' }}>
									{file.title}
								</Typography>
							</ListItemButton>)}
						</ListItem>
					</List>
				</AccordionDetails>
			</Accordion>)}
		</div>
	);
}