import apiClient from "./client";

const key = "/users";
const getUser = async (id) => {
	try {
		const response = await apiClient.get(key + "/" + id);
		return response.data;
	} catch (error) {
		console.log("EROOORGETUSER", error.response);
	}
};
const getUsers = async () => {
	try {
		const response = await apiClient.get(key);
		return response.data["hydra:member"];
	} catch (error) {
		console.log("EROOORGETUSERS", error.response);
	}
};
const getFreelancers = async () => {
	try {
		const response = await apiClient.get("/freelancers");
		return response.data["hydra:member"];
	} catch (error) {
		console.log("EROOORGETUSERS", error.response);
	}
};
const getUserReports = async (id) => {
	try {
		return await apiClient.get(
			key + "/" + id + "/reports", { params: { id: id } }
		);
	} catch (error) {
		console.log("ERRROOORRGETUSERREPORTS", error)
	}
};

const deleteUser = async (id) => {
	return await apiClient.delete(key + "/" + id);
};

const deleteFreelancer = async (id) => {
	return await apiClient.delete("/freelancers/" + id);
};

const createUser = async (values) => {
	return await apiClient.post(key, {
		email: values?.email,
		password: values?.password,
		phone: values?.phone,
		firstname: values?.firstname,
		lastname: values?.lastname,
		roles: [values?.roles],
		tag: values?.tag
	});
};
const createFreelancer = async (values) => {
	return await apiClient.post("/freelancers", {
		firtsname: values?.firtsname,
		lastname: values?.lastname,
		job: values?.job
	});
};

const getConnectedUserReports = async (id) => {
	return await apiClient.get(key + "/" + id + "/myreports")
}

const userApi = {
	getUser,
	getUsers,
	deleteUser,
	createUser,
	getUserReports,
	getConnectedUserReports,
	getFreelancers,
	createFreelancer,
	deleteFreelancer
}
export default userApi;
