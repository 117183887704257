import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import reportsApi from "../../api/reportsApi";
import { useNavigate } from "react-router-dom";
import ReportsTable from "../../Components/ReportsComponents/ReportsTable";
import { useAppContext } from "../../contexts/AppContext";
import DeleteAlert from "../../Components/DialogAlert/DeleteAlert";
import SearchBar from "../../Components/SearchBar";
// import RangeSearch from "../../Components/RangeSearch";
import AppFab from "../../Components/AppButton.jsx/AppFab";
import LoadingTable from "../../Components/LoadingTable";
import { Stack } from '@mui/material';
import routes from "../../Services/routes";


export default function ReportsScreen() {
	const [page, setPage] = useState(1);
	const [selectedFilter, setSelectedFilter] = useState("ALL")



	const navigate = useNavigate();

	const {
		reports,
		setReports,
		getReports,
		isAlertOpen,
		setIsAlertOpen,
		search,
		setSearch,
		setIsLoading,
		isLoading,
		getUser,
		getUsers,
		report,
		setReport,
		getFreelancers,
		originalReports
	} = useAppContext();

	const handleClickOpen = (report) => {
		setReport(report);
		setIsAlertOpen(true);
	};

	const handleClose = () => {
		setReport(null);
		setIsAlertOpen(false);
	};

	const closeAndNotify = (message) => {
		toast(message, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "dark",
		});
		handleClose();
	};

	const handleReportDelete = async () => {
		let originalReports = [...reports];
		setReports(reports.filter((item) => item.id !== report.id));
		try {
			await reportsApi.deleteItem(report?.id);
		} catch (error) {
			setReports(originalReports);
			toast.error("Something went wrong, try again.", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			console.log("EROOR DELETE REPORT", error);
		} finally {
			closeAndNotify("Report Deleted");
		}
	};
	const handleGetReports = async () => {
		setIsLoading(true);
		try {
			await getReports();
			await getUsers()
			await getUser()
			await getFreelancers()
		} catch (error) {
			console.log("getreportserror", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		handleGetReports();
	}, []);

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const searchedReports = reports?.filter(
		(r) =>
			r?.project?.code.toLowerCase().includes(search.toString().toLowerCase())
			||
			r?.location
				.toLowerCase()
				.includes(search.toString().toLowerCase())
			||
			r?.project?.project_name
				.toLowerCase()
				.includes(search.toString().toLowerCase())
			||
			r?.contact
				.toLowerCase()
				.includes(search.toString().toLowerCase())
	);

	// const rangeSearchedReports = (range) => {
	// 	setPage(1)
	// 	let searchedReports = reports?.filter(
	// 		(r) => new Date(r?.date).getTime() >= new Date(range[0]).getTime() && new Date(r?.date).getTime() <= new Date(range[1]).getTime());
	// 	setReports(searchedReports)
	// }


	const handleSearch = (event) => {
		const value = event.currentTarget.value;
		setPage(1);
		setSearch(value);
	};
	const itemsPerPage = 30;
	const pageCount = Math.ceil(searchedReports?.length / itemsPerPage);
	const start = page * itemsPerPage - itemsPerPage;

	const paginatedReports = searchedReports?.slice(start, start + itemsPerPage);

	// const handleUsersFilter = async (id) => {
	// 	setIsLoading(true)
	// 	setSelectedChip(null)
	// 	setSelectedFilter("")
	// 	try {
	// 		const response = await userApi.getUserReports(id)
	// 		setReports(response.data)
	// 		setIsLoading(false)
	// 		setSelectedChip(id)
	// 	} catch (error) {
	// 		console.log("ERRRRORRRGETONEUSERREPORTS", error)
	// 	}
	// }

	const handleUsersFilter = (u) => {
		setSelectedFilter(u)
		const result = [...originalReports]?.filter(r => {
			return r.user.some(us => us.id === u);
		});
		setReports(result)
	}

	const handleFreelancersFilter = async () => {
		setSelectedFilter("FREELANCER")
		let freelancersReports = [...originalReports]?.filter(r => r?.freelancers.length > 0)
		setReports(freelancersReports)
	}

	const getProcessedReports = async () => {
		setSelectedFilter("PROCESSED")
		let processedReports = [...originalReports]?.filter(r => r?.processed === true)
		setReports(processedReports)
	}

	const getUnProcessedReports = async () => {
		setSelectedFilter("UNPROCESSED")
		let unProcessedReports = [...originalReports]?.filter(r => r?.processed === false)
		setReports(unProcessedReports)
	}

	const getBisReports = async () => {
		setSelectedFilter("BIS")
		let toSearch = "BIS"
		let bisReports = [...originalReports]?.filter(r => r?.customer?.toLowerCase()
			.includes(toSearch.toString().toLowerCase()))
		setReports(bisReports)
	}

	const getRocheReports = async () => {
		setSelectedFilter("ROCHE")
		let toSearch = "ROCHE"
		let rocheReports = [...originalReports]?.filter(r => r?.customer?.toLowerCase()
			.includes(toSearch.toString().toLowerCase()))
		setReports(rocheReports)
	}



	return (
		<>
			<AppFab
				onClick={() => navigate(routes.newReport)}
				color="primary"
			/>
			<Stack spacing={4} alignItems="center">
				<SearchBar
					handleUsersFilter={handleUsersFilter}
					handleFreelancersFilter={handleFreelancersFilter}
					onChange={handleSearch}
					value={search}
					placeholder="Search Per Project's code or Name, Location or contact"
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
					getProcessedReports={getProcessedReports}
					getUnProcessedReports={getUnProcessedReports}
					getBisReports={getBisReports}
					getRocheReports={getRocheReports}
				/>
				{/* {
					isAdmin && <Stack direction="row" alignItems="center" spacing={2}>
						<RangeSearch value={value} onChange={onChange} />
						<IconButton color="primary" aria-label="filter" onClick={() => rangeSearchedReports(value)}>
							<ContentPasteSearchIcon />
						</IconButton>
					</Stack>
				} */}
			</Stack>
			{pageCount > 1 && (
				<Pagination
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					color="primary"
				/>
			)}

			{isLoading ? (
				<LoadingTable />
			) : (
				<ReportsTable data={paginatedReports} openDialog={handleClickOpen} />
			)}

			{pageCount > 1 && (
				<Pagination
					sx={{ p: 2 }}
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					color="primary"
				/>
			)}

			<ToastContainer />

			<DeleteAlert
				open={isAlertOpen}
				title="Delete Report"
				data={report}
				handleDelete={handleReportDelete}
				clickClose={handleClose}
			/>
		</>
	);
}
