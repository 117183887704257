import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormTitle from "../../Components/FomsComponents/FormTitle";
import FormButton from "../../Components/FomsComponents/FormButton";
import FormTextField from "../../Components/FomsComponents/FormTextField";
import projectsApi from "../../api/projectsApi";
import FormStatusSelect from "../../Components/FomsComponents/FormStatusSelect";
import routes from "../../Services/routes";

export default function EditProjectScreen() {

	const navigate = useNavigate();
	const { state: project } = useLocation();

	const editProject = async (values) => {
		try {
			await projectsApi.editProject(values, project.id);
			toast("Project Edited", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setTimeout(() => {
				navigate(routes.projects);
			}, 2000);
		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORPOSTPROJ", error.response);
			toast.error(error.response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}
	};
	const formik = useFormik({
		initialValues: {
			code: project?.code,
			projectName: project?.project_name,
			status: project?.status,
		},
		validationSchema: Yup.object().shape({
			code: Yup.string().required("Code is Required"),
			projectName: Yup.string().required("Name is required").nullable(),
			status: Yup.string(),
		}),

		onSubmit: (values) => {
			editProject(values);
		},
	});

	return (
		<>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					alignItems: "center",
					display: "flex",
					flexGrow: 1,
					minHeight: "100%",
				}}
			>
				<Container maxWidth="sm">
					<form onSubmit={formik.handleSubmit}>
						<FormTitle title={"Edit Project"} action={() => navigate(-1)} />

						<FormTextField
							error={Boolean(formik.touched.code && formik.errors.code)}
							helperText={formik.touched.code && formik.errors.code}
							label="Project code"
							onBlur={formik.handleBlur("code")}
							onChange={formik.handleChange("code")}
							value={formik.values.code}
						/>

						<FormTextField
							error={Boolean(formik.touched.projectName && formik.errors.projectName)}
							helperText={formik.touched.projectName && formik.errors.projectName}
							label="Project Name"
							onBlur={formik.handleBlur("projectName")}
							onChange={formik.handleChange("projectName")}
							value={formik.values.projectName}
						/>

						<FormStatusSelect
							error={Boolean(formik.touched.status && formik.errors.status)}
							label="Status"
							value={formik.values.status}
							onChange={formik.handleChange("status")}
							helper={formik.touched.status && formik.errors.status}
						/>

						<FormButton text={"Edit Project"} disabled={formik.isSubmitting} />
					</form>
				</Container>
			</Box>
		</>
	);
}
