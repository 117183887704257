import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import AppFab from "../../Components/AppButton.jsx/AppFab";
import DeleteAlert from "../../Components/DialogAlert/DeleteAlert";
import LoadingTable from "../../Components/LoadingTable";
import userApi from "../../api/userApi";
import UsersTable from "../../Components/UsersComponents/UsersTable";
import routes from "../../Services/routes";

export default function AdminUsersScreen() {
	const {
		users,
		isAlertOpen,
		setIsAlertOpen,
		setIsLoading,
		isLoading,
		getUsers,
		setUsers,
		freelancers
	} = useAppContext();
	const [user, setUser] = useState(null);
	const [page, setPage] = useState(1);
	const navigate = useNavigate();

	const handleClickOpen = (user) => {
		setUser(user);
		setIsAlertOpen(true);
	};

	const handleClose = () => {
		setUser(null);
		setIsAlertOpen(false);
	};

	const closeAndNotify = (message) => {
		toast(message, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "dark",
		});
		handleClose();
	};

	const handleUserDelete = async () => {
		let originalUsers = [...users];
		setUsers(users.filter((item) => item.id !== user.id));
		try {
			await userApi.deleteUser(user.id);
		} catch (error) {
			setUsers(originalUsers);
			toast.error("Something went wrong, try again.", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			console.log("EROOR DELETE USER", error);
		} finally {
			closeAndNotify("User Deleted");
		}
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};
	const handleGetUsers = async () => {
		setIsLoading(true);
		try {
			await getUsers();
		} catch (error) {
			console.log("getuserserror", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		handleGetUsers();
	}, []);


	const itemsPerPage = 10;
	const pageCount = Math.ceil(users?.length / itemsPerPage);
	const start = page * itemsPerPage - itemsPerPage;

	const paginatedUsers = users?.slice(start, start + itemsPerPage);

	return (
		<>
			<ToastContainer />
			<AppFab
				onClick={() => navigate(routes.newUser)}
				into={users?.length >= 0 ? true : false}
				color="secondary"
			/>
			{isLoading ? (
				<LoadingTable />
			) : (
				<UsersTable users={paginatedUsers} openDialog={handleClickOpen} freelancers={freelancers} />
			)}

			{pageCount > 1 && (
				<Pagination
					sx={{ p: 2 }}
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					color="primary"
				/>
			)}

			<DeleteAlert
				open={isAlertOpen}
				title="Delete User"
				data={user}
				handleDelete={handleUserDelete}
				clickClose={handleClose}
			/>
		</>
	);
}
