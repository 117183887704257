import apiClient from "./client";
import jwt_decode from "jwt-decode";
import { API_LOGIN } from "../Services/config";

const key = "authToken";
const login = async (values) => {
	try {
		const response = await apiClient.post(API_LOGIN, values);
		let token = response.data.token;
		const decoded = jwt_decode(token);
		const userId = decoded.id;
		const isAdmin = decoded.roles.includes("ROLE_ADMIN");
		window.localStorage.setItem(key, token);
		apiClient.interceptors.request.use(function (config) {
			const token = window.localStorage.getItem("authToken");
			config.headers.Authorization = token ? `Bearer ${token}` : "";
			return config;
		});
		return { ...response, isAdmin, userId };
	} catch (error) {
		console.log("ERRRRORAUTHAPI", error);
	}
};

const logout = () => {
	window.localStorage.removeItem(key);
	apiClient.interceptors.request.use(function (config) {
		delete config.headers.Authorization;
		return config;
	});
};

const authApi = {
	login,
	logout,
};

export default authApi
