import apiClient from "./client";


export const getFile = (fileName) => apiClient.get(`/download/${fileName}`, { responseType: 'blob' })
	.then(response => {
		// Gérez la réponse ici
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	})
	.catch(error => {
		console.log(error)
	});

const filesDownloadApi = {
	getFile
}
export default filesDownloadApi 