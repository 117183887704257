import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import reportsApi from "../../api/reportsApi";
import fileDownload from "js-file-download";
import { useAppContext } from "../../contexts/AppContext";
import { Avatar, Button, Typography } from "@mui/material";
import FormStatusSelect from "../FomsComponents/FormStatusSelect";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Fade from '@mui/material/Fade';
import routes from "../../Services/routes";

export default function ReportsTable({ data }) {
	const navigate = useNavigate();
	const [statusSelected, setStatusSelected] = React.useState(null);
	const { getReports, user } = useAppContext();

	const getTimeFromMins = (mins) => {
		if (mins >= 24 * 60 || mins < 0) {
			throw new RangeError(
				"Valid input should be greater than or equal to 0 and less than 1440."
			);
		}
		let h = (mins / 60) | 0,
			m = mins % 60 | 0;
		return moment.utc().hours(h).minutes(m).format("HH:mm");
	};

	// const generateExcel = async (report) => {
	// 	try {
	// 		const file = await reportsApi?.reportExcel(report?.id);
	// 		fileDownload(
	// 			file?.data,
	// 			`Report made By ${report?.user?.fullName} on ${moment(report?.date).format(
	// 				"MMM Do YY"
	// 			)}.xlsx`
	// 		);
	// 	} catch (error) {
	// 		console.log("EROOOREXCEL", error);
	// 	}
	// };
	const generatePdf = async (report) => {
		try {
			const file = await reportsApi.reportPdf(report.id);
			fileDownload(
				file.data,
				`Report made on ${moment(report.date).format(
					"MMM Do YY"
				)}.pdf`
			);
		} catch (error) {
			console.log("EROOORPDF", error);
		}
	};


	const handleChangeStatus = (event, report) => {
		setStatusSelected({ id: report?.id, status: event?.target?.value })
	}

	const setProjectStatus = async (report) => {
		try {
			await reportsApi.handleProjectStatus(report?.id, statusSelected?.status)
			await getReports()
		} catch (error) {
			console.log("ERROOORRRHANDLESTATUS", error)
		}
		setStatusSelected(null)
	}

	const handleProcessed = async (report) => {
		try {
			await reportsApi.handleProcessed(report?.id, !report?.processed)
			await getReports()
		} catch (error) {
			console.log("ERRROOORPROCESSED", error)
		}
	}
	return (
		<Fade in={true} timeout={1500}>
			<TableContainer component={Paper} >
				<Table sx={{ minWidth: 650 }} aria-label="report table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							{user?.roles?.includes("ROLE_MANAGER") && <TableCell align="left">Processed</TableCell>}
							<TableCell align="left">Date</TableCell>
							<TableCell align="left">User (s)</TableCell>
							<TableCell align="left">Freelance (s)</TableCell>
							<TableCell align="left">Project code</TableCell>
							<TableCell align="left">Project Name</TableCell>
							<TableCell align="left">Project status</TableCell>
							<TableCell align="left">Location</TableCell>
							<TableCell align="left">Contact</TableCell>
							<TableCell align="left">Extra Expenses (chf)</TableCell>
							<TableCell align="left">Break Duration (h)</TableCell>
							<TableCell align="left">Post Production (h)</TableCell>
							<TableCell align="left">Travel Cost</TableCell>
							<TableCell align="left">Created</TableCell>
							<TableCell align="left">Updated</TableCell>
							<TableCell align="left">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!data && <Typography variant="caption" color="initial">Hmm...No results</Typography>}
						{data?.map((report) => (
							<TableRow
								key={report.id}
								sx={{
									backgroundColor: report?.project?.status === "Done" ? "#95DB9A" : report?.project?.status === "In Process" ? "#938DE2" : report?.project?.status === "Pending" ? "#ED975A" : report?.project?.status === "Cancelled" ? "fffff" : "#AFB8C0",
									"&:last-child td, &:last-child th": { border: 0 },
									"&:hover": {
										opacity: [0.9, 0.8, 0.9],
										cursor: "pointer",
									},
									textDecoration:
										report?.project?.status === "Cancelled" && "line-through",
								}}
							>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									component="th"
									scope="row"
								>
									{report.id}
								</TableCell>
								{user?.roles?.includes("ROLE_MANAGER") &&
									<TableCell
										component="th"
										scope="row"
									>
										<IconButton sx={{ backgroundColor: "lightgray" }} onClick={() => handleProcessed(report)}>
											{report?.processed === true ? <CheckIcon color="success" /> : <CloseIcon color="warning" />}
										</IconButton>

									</TableCell>}
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{moment(report?.date).format("MMM Do YY")}
								</TableCell>

								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.user?.map((u) =>
										<Chip
											avatar={u?.avatar && <Avatar alt="Avatar" src={`../../assets/images/${u?.avatar}`} />}
											sx={{ m: 0.5 }}
											variant="filled"
											key={u.id}
											label={u.firstname}
										/>)}
								</TableCell>

								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.freelancers.length > 0 ? report?.freelancers?.map((f) =>
										<Chip
											size="small"
											color="secondary"
											sx={{ m: 0.5 }}
											variant="filled"
											key={f.id}
											label={f.lastname}
										/>)
										:
										"No Freelancer"
									}
								</TableCell>

								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.project?.code || "No Linked Project"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.project?.project_name || "No Linked Project"}
								</TableCell>
								<TableCell
									align="left"
								>
									{
										!report?.project
											?
											"No Linked Project"
											:
											<>
												<FormStatusSelect
													defaultValue={report?.project?.status}
													label="Status"
													value={!statusSelected ? report?.project?.status : statusSelected?.id === report?.id ? statusSelected?.status : report?.project?.status}
													onChange={(event) => handleChangeStatus(event, report)}
												/>
												<Button disabled={statusSelected?.id === report.id && statusSelected !== null ? false : true} onClick={() => setProjectStatus(report)} size="small">Ok</Button>
											</>
									}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.location || "No Linked Location"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.contact || "No Linked Manager"}
								</TableCell>

								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.expense || "No Expenses"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.break_duration != null
										? report?.break_duration + " min"
										: "No Break"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.post_prod_duration != null
										? report?.post_prod_duration + " min"
										: "No Post-Prod"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.travel_cost ? "YES" : "NO"}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{moment(report?.created_at).format("MMM Do YY, H:mm")}
								</TableCell>
								<TableCell
									onClick={() => navigate(routes.reportDetails, { state: report })}
									align="left"
								>
									{report?.updated_at
										? moment(report?.updated_at).format("MMM Do YY, H:mm")
										: "No Update Yet"}
								</TableCell>

								<TableCell align="left">
									<IconButton
										sx={{ color: "black" }}
										aria-label="Excel"
										onClick={() => generatePdf(report)}
									>
										<PictureAsPdfIcon />
									</IconButton>
									{/* <IconButton
										sx={{ color: "red" }}
										aria-label="Excel"
										onClick={() => generateExcel(report)}
									>
										<PictureAsPdfIcon />
									</IconButton> */}

								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade >
	);
}
