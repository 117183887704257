import apiClient from "./client";
import moment from "moment";

const key = "/reports";
const findAll = async () => {
	const response = await apiClient.get(key);
	return response.data["hydra:member"];
};

const deleteItem = async (id) => {
	return await apiClient.delete(key + "/" + id);
};

const createReport = async (values) => {
	let teammates = []
	values?.user?.forEach(element => teammates.push("/api/users/" + element))
	let freelancers = []
	values?.freelancers?.forEach(element => freelancers.push("/api/freelancers/" + element))
	let object = {
		date: moment(values?.date).format(),
		project: values?.project ? "/api/projects/" + values.project.id : null,
		getin: !values.getin ? null : moment(values.getin).format("LT"),
		start: moment(values.start).format("LT"),
		end: moment(values.end).format("LT"),
		expense: values.expense,
		remarks: values.remarks,
		breakStart: values.break === "" ? null : values.break,
		breakEnd: values.breakEnd === "" ? null : values.breakEnd,
		postProdStart: values.postProd === "" ? null : values.postProd,
		postProdEnd: values.postProdEnd === "" ? null : values.postProdEnd,
		travelCost: values.travelCost,
		contact: values.contact,
		processed: values.processed,
		customer: values.customer,
		location: values.location,
		user: teammates,
		freelancers: freelancers,
		prepStart: values.prepStart === "" ? null : values.prepStart,
		prepEnd: values.prepEnd === "" ? null : values.prepEnd,
	}
	return await apiClient.post(key, object);
};
const reportExcel = async (id) => {
	return await apiClient.get(
		key + "/" + id + "/excel",
		{
			responseType: "blob",
		},
		{ id }
	);
};
const reportPdf = async (id) => {
	return await apiClient.get(
		key + "/" + id + "/pdf",
		{
			responseType: "blob",
		},
		{ id }
	);
};

const editReport = async (values, id) => {
	let object = {
		date: moment(values?.date).format(),
		project: values?.project ? "/api/projects/" + values.project : null,
		getin: !values?.getin ? null : moment(values?.getin).format("LT"),
		start: moment(values?.start).format("LT"),
		end: moment(values?.end).format("LT"),
		expense: values?.expense,
		remarks: values?.remarks,
		breakStart: values?.break === "" ? null : values?.break,
		breakEnd: values?.breakEnd === "" ? null : values?.breakEnd,
		postProdStart: values?.postProd === "" ? null : values?.postProd,
		postProdEnd: values?.postProdEnd === "" ? null : values?.postProdEnd,
		travelCost: values?.travelCost,
		contact: values?.contact,
		processed: values?.processed,
		customer: values?.customer,
		location: values?.location,
		prepStart: values.prepStart === "" ? null : values.prepStart,
		prepEnd: values.prepEnd === "" ? null : values.prepEnd,

	}

	return await apiClient.put(key + "/" + id, object);
};

const removeUserFromReport = async (reportId, userId) => {
	return await apiClient.get(key + "/remove/" + reportId + "/user", { params: { userId } });
}

const removeFreelancerFromReport = async (reportId, freelancerId) => {
	return await apiClient.get(key + "/remove/" + reportId + "/freelancer", { params: { freelancerId } });
}

const addUserToReport = async (reportId, userId) => {
	return await apiClient.get(key + "/add/" + reportId + "/user", { params: { userId } });
}

const addFreelancerToReport = async (reportId, freelancerId) => {
	return await apiClient.get(key + "/add/" + reportId + "/freelancer", { params: { freelancerId } });
}

const handleProjectStatus = async (reportId, status) => {
	return await apiClient.get(key + "/set/" + reportId + "/project", { params: { status } });
}

const handleProcessed = async (reportId, processed) => {
	return await apiClient.get(key + "/set/" + reportId + "/report", { params: { processed } });
}


const reportsApi = {
	findAll,
	deleteItem,
	createReport,
	editReport,
	reportExcel,
	reportPdf,
	removeUserFromReport,
	addUserToReport,
	handleProjectStatus,
	handleProcessed,
	addFreelancerToReport,
	removeFreelancerFromReport
}

export default reportsApi;
