import React from "react";
import { TextField, FormControl, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";


export default function FormDatePicker({
	onChange,
	onError,
	onBlur,
	helper,
	error,
	value,
}) {


	moment.updateLocale("en", {
		week: {
			dow: 1
		}
	});

	return (


		<FormControl error={error} fullWidth sx={{ my: 1 }}>
			<DatePicker
				label="Choose A Date"
				disableFuture
				value={value}
				onChange={onChange}
				onError={onError}
				renderInput={(params) => (
					<TextField onBlur={onBlur} fullWidth {...params} />
				)}
			/>
			<FormHelperText>{helper}</FormHelperText>
		</FormControl>
	);
}
