import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function DeleteAlert({
  open,
  title,
  data,
  handleDelete,
  clickClose,
}) {
  return (
    <Dialog
      open={open}
      onClose={clickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <span style={{ fontWeight: "bold", color: "red" }}> {data?.id}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are going to delete this, are you sure ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>Delete</Button>
        <Button onClick={clickClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
