import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import FormTextField from "./FormTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "./FormButton";
import { Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userApi from "../../api/userApi";
import { useAppContext } from "../../contexts/AppContext";
import { FormHelperText } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import FormTimePicker from "./FormTimePicker";


function SimpleDialog(props) {
	const { onClose, selectedFreelance, open, freelancers, handleSelect, visbleForm, setVisibleForm, frelanceForm } = props;

	const handleClose = () => {
		onClose(selectedFreelance);
	};

	const handleListItemClick = (freelancer) => {
		selectedFreelance?.includes(freelancer) ? selectedFreelance?.splice(selectedFreelance?.indexOf(freelancer), 1) : selectedFreelance?.push(freelancer)
		let freelancerIds = selectedFreelance?.map(f => f?.id)
		handleSelect("freelancers", freelancerIds);
		handleClose()
	};

	const handleFreelanceForm = () => {
		setVisibleForm(visbleForm ? false : true)
	}
	return (
		<Dialog onClose={handleClose} open={open}>
			<ToastContainer />
			<DialogTitle>{visbleForm ? "Create Freelance" : "Select Freelance"}</DialogTitle>
			<FormControl sx={{ my: 1 }} fullWidth>
				<List sx={{ pt: 0 }}>
					<ListItem disableGutters>
						<ListItemButton onClick={() => handleFreelanceForm()}>
							<ListItemText primary={visbleForm ? "⬅️ Go back" : "➕ Add Freelance"}
							/>

						</ListItemButton>
					</ListItem>

					{!visbleForm && freelancers?.map((freelancer) => (
						<ListItem key={freelancer?.id} disableGutters>
							<ListItemButton onClick={() => handleListItemClick(freelancer)}>
								{
									selectedFreelance?.includes(freelancer) ?
										<ListItemText primary={"✔️   " + freelancer?.firtsname + " " + freelancer?.lastname} />
										:
										<ListItemText ItemText primary={freelancer?.firtsname + " " + freelancer?.lastname} />
								}
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</FormControl>
			{visbleForm &&
				<Stack spacing={4} sx={{ m: 4 }}>
					<form onSubmit={frelanceForm.handleSubmit}>

						<FormTextField
							error={Boolean(frelanceForm?.touched?.firtsname && frelanceForm?.errors?.firtsname)}
							helperText={frelanceForm?.touched?.firtsname && frelanceForm?.errors?.firtsname}
							label="Firtsname"
							onBlur={frelanceForm?.handleBlur("firtsname")}
							onChange={frelanceForm?.handleChange("firtsname")}
							value={frelanceForm?.values?.firtsname}
						/>
						<FormTextField
							error={Boolean(frelanceForm?.touched?.lastname && frelanceForm?.errors?.lastname)}
							helperText={frelanceForm?.touched?.lastname && frelanceForm?.errors?.lastname}
							label="Lastname"
							onBlur={frelanceForm?.handleBlur("lastname")}
							onChange={frelanceForm?.handleChange("lastname")}
							value={frelanceForm?.values?.lastname}
						/>
						<FormTextField
							error={Boolean(frelanceForm?.touched?.job && frelanceForm?.errors?.job)}
							helperText={frelanceForm?.touched?.job && frelanceForm?.errors?.job}
							label="Job"
							onBlur={frelanceForm?.handleBlur("job")}
							onChange={frelanceForm?.handleChange("job")}
							value={frelanceForm?.values?.job}
						/>

						<FormButton text={"Create Freelance"} disabled={frelanceForm?.isSubmitting} />
					</form>
				</Stack>
			}
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedFreelance: PropTypes.array.isRequired,
};

export default function MultipleSelectFreelancers({ freelancers, handleSelect, user, helper }) {
	const [open, setOpen] = React.useState(false);
	const [selectedFreelance, setSelectedFreelance] = React.useState([]);
	const [visbleForm, setVisibleForm] = React.useState(false)
	const { getFreelancers } = useAppContext()
	const addFreelancer = async (values) => {
		try {
			await userApi.createFreelancer(values)
			toast("Freelance Created", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			await getFreelancers()
			setVisibleForm(false)

		} catch (error) {
			frelanceFormik.setSubmitting(false);
			console.log("EROOORADDFREELANCE", error);
			toast.error(error?.response?.data?.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}

	}



	const frelanceFormik = useFormik({
		initialValues: {
			firtsname: "",
			lastname: "",
			job: ""
		},
		validationSchema: Yup.object().shape({
			firtsname: Yup.string().required("Firstname is required"),
			lastname: Yup.string().required("Firstname is required"),
			job: Yup.string()
		}),

		onSubmit: (values) => {
			// console.log(values);
			addFreelancer(values);
		},
	})

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedFreelance(value);

	};

	// const formikFSchedule = useFormik({
	// 	initialValues: {
	// 		start: null,
	// 		end: null,
	// 	},

	// 	validationSchema: Yup.object().shape({
	// 		start: Yup.string().required("Start is required").nullable(),
	// 		end: Yup.string().required("End is required").nullable(),
	// 	}),

	// });

	return (
		<Box >
			<Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
				<Button color="secondary" variant="outlined" onClick={handleClickOpen} endIcon={<PersonIcon />}>
					Add Freelance
				</Button>
				<FormHelperText sx={{ ml: 2 }}>{helper}</FormHelperText>
			</Box>
			<Typography sx={{ mx: 1 }} variant="subtitle1" component="div">
				{selectedFreelance?.map(value => <Chip color="secondary" sx={{ mx: 0.5 }} key={value?.id} label={value?.firtsname?.charAt(0) + value?.lastname?.charAt(0)} />)}
			</Typography>

			<SimpleDialog
				user={user}
				freelancers={freelancers}
				selectedFreelance={selectedFreelance}
				open={open}
				onClose={handleClose}
				handleSelect={handleSelect}
				visbleForm={visbleForm}
				setVisibleForm={setVisibleForm}
				frelanceForm={frelanceFormik}
			/>
		</Box>
	);
}
