import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import DeleteIconButton from "../AppButton.jsx/DeleteIconButton";
import routes from "../../Services/routes";

export default function ProjectsTable({ data, openDialog }) {
	const navigate = useNavigate();

	return (
		<TableContainer component={Paper} sx={{ py: 2 }}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell align="left">Code</TableCell>
						<TableCell align="left">Name</TableCell>
						<TableCell align="left">Status</TableCell>
						<TableCell align="left">Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.map((project) => (
						<TableRow
							key={project.id}
							sx={{
								backgroundColor: (project?.status === "Done" ? "#95DB9A" : project?.status === "In Process" ? "#938DE2" : project?.status === "Pending" ? "#ED975A" : "#AFB8C0"),
								"&:last-child td, &:last-child th": { border: 0 },
								"&:hover": {
									backgroundColor: "lightgray",
									opacity: [0.9, 0.8, 0.7],
									cursor: "pointer",
								},
								textDecoration:
									project?.status === "Cancelled" && "line-through",
							}}
						>
							<TableCell
								onClick={() => navigate(routes.projectDetails, { state: project })}
								component="th"
								scope="row"
							>
								{project.id}
							</TableCell>
							<TableCell
								onClick={() => navigate(routes.projectDetails, { state: project })}
								align="left"
							>
								{project?.code}
							</TableCell>
							<TableCell
								onClick={() => navigate(routes.projectDetails, { state: project })}
								align="left"
							>
								{project?.project_name}
							</TableCell>
							<TableCell
								onClick={() => navigate(routes.projectDetails, { state: project })}
								align="left"
							>
								{project?.status === "In Process" && "In Process"}
								{project?.status === "Done" && "Done"}
							</TableCell>
							<TableCell align="left">
								<DeleteIconButton handleDelete={() => openDialog(project)} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
