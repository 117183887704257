import React from "react";
import { TextField, FormControl, FormHelperText } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function FormTimePicker({
  error = false,
  label,
  onError,
  value,
  onChange,
  onBlur,
  helper,
}) {
  return (
    <FormControl error={error} fullWidth sx={{ my: 1 }}>
      <TimePicker
        ampm={false}
        label={label}
        onError={onError}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField onBlur={onBlur} fullWidth {...params} />
        )}
      />
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
}
