import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import reportsApi from "../../api/reportsApi";
import { useAppContext } from "../../contexts/AppContext";
import FormSelect from "../../Components/FomsComponents/FormSelect";
import FormTimePicker from "../../Components/FomsComponents/FormTimePicker";
import FormDatePicker from "../../Components/FomsComponents/FormDatePicker";
import FormTitle from "../../Components/FomsComponents/FormTitle";
import FormTextField from "../../Components/FomsComponents/FormTextField";
import FormCheckbox from "../../Components/FomsComponents/FormCheckBox";
import FormButton from "../../Components/FomsComponents/FormButton";
import routes from "../../Services/routes";

const EditReportScreen = () => {
	const { projects,
		getProjects,
		getUsers,
		isAdmin,
		// showBreakInput,
		// setShowBreakInput,
		// showPrepInput,
		// setShowPrepInput,
		// showPostProdInput,
		// setShowPostProdInput,
	} = useAppContext();

	const { state: report } = useLocation();
	const filteredProjectsByStatus = projects?.filter(project => project?.status !== "Done" && project?.status !== "Cancelled")

	const navigate = useNavigate();
	const editReport = async (values) => {
		try {
			await reportsApi.editReport(values, report?.id);
			toast("Report Edited", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setTimeout(() => {
				navigate(routes.reports);
			}, 2000);
		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORPOSTREPORT", error.response);
			toast.error(error.response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
		}
	};
	const formik = useFormik({
		initialValues: {
			date: report?.date,
			getin: report?.getin,
			start: report?.start,
			end: report?.end,
			expense: report?.expense,
			remarks: report?.remarks,
			project: report?.project?.id,
			break: report?.break_start || null,
			breakEnd: report?.break_end || null,
			postProd: report?.post_prod_start || null,
			postProdEnd: report?.post_prod_end || null,
			travelCost: report?.travel_cost || false,
			location: report?.location,
			contact: report?.contact,
			customer: report?.customer,
			user: report?.user,
			processed: report?.processed,
			prepStart: report?.prep || null,
			prepEnd: report?.prepEnd || null,


		},
		validationSchema: Yup.object().shape({
			project: Yup.string().nullable(),
			location: Yup.string().nullable(),
			customer: Yup.string().nullable(),
			date: Yup.string().required("Date is required").nullable(),
			getin: Yup.string().nullable(),
			start: Yup.string().required("Start is required").nullable(),
			end: Yup.string().required("End is required").nullable(),
			expense: Yup.string().nullable(),
			remarks: Yup.string(),
			break: Yup.string().nullable(),
			breakEnd: Yup.string().nullable(),
			postProd: Yup.string().nullable(),
			postProdEnd: Yup.string().nullable(),
			travelCost: Yup.boolean().nullable(),
			processed: Yup.boolean().nullable(),
			contact: Yup.string().nullable(),
			user: Yup.array().nullable(),
			prepStart: Yup.string().nullable(),
			prepEnd: Yup.string().nullable(),
		}),
		onSubmit: (values) => {
			editReport(values);
		},
	});

	useEffect(() => {
		getProjects();
		getUsers()
	}, []);



	// const handleBreakSwitch = () => {
	// 	setShowBreakInput(!showBreakInput);
	// 	formik.setFieldValue("break", null);
	// 	formik.setFieldValue("breakEnd", null);
	// };
	// const handlePostProdSwitch = () => {
	// 	setShowPostProdInput(!showPostProdInput);
	// 	formik.setFieldValue("postProd", null);
	// 	formik.setFieldValue("postProdEnd", null);
	// };
	// const handlePrepSwitch = () => {
	// 	setShowPrepInput(!showPrepInput);
	// 	formik.setFieldValue("prepStart", null);
	// 	formik.setFieldValue("prepEnd", null);
	// };

	return (
		<>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					alignItems: "center",
					display: "flex",
					flexGrow: 1,
					minHeight: "100%",
				}}
			>
				<Container maxWidth="sm">
					<form onSubmit={formik?.handleSubmit}>
						<FormTitle title={"Edit Report"} action={() => navigate(-1)} />

						<FormSelect
							defaultValue={report?.project?.id}
							error={Boolean(formik?.touched?.project && formik?.errors?.project)}
							label="Project"
							value={formik?.values?.project || ""}
							onChange={formik?.handleChange("project")}
							data={filteredProjectsByStatus}
							helper={formik?.touched?.project && formik?.errors?.project}
						/>

						<LocalizationProvider dateAdapter={AdapterMoment}>
							<FormDatePicker
								value={formik?.values?.date}
								onChange={(value) => formik.setFieldValue("date", value)}
								onError={() => formik?.setErrors("date", "Date Is Required")}
								onBlur={formik?.handleBlur("date")}
								helper={formik?.touched?.date && formik?.errors?.date}
								error={Boolean(formik?.touched?.date && formik?.errors?.date)}
							/>

							<FormTimePicker
								error={Boolean(formik?.touched?.getin && formik?.errors?.getin)}
								label="Choose A Get In Time"
								onError={() => formik?.setErrors("getin", "Get In Is Required")}
								value={formik?.values?.getin}
								onChange={(value) => formik.setFieldValue("getin", value?._d)}
								onBlur={formik?.handleBlur("getin")}
								helper={formik?.touched?.getin && formik?.errors?.getin}
							/>

							<FormTimePicker
								error={Boolean(formik?.touched?.start && formik?.errors?.start)}
								label="Choose A Start Time"
								onError={() => formik?.setErrors("start", "Start Is Required")}
								value={formik?.values?.start}
								onChange={(value) => formik?.setFieldValue("start", value?._d)}
								onBlur={formik?.handleBlur("start")}
								helper={formik?.touched?.start && formik?.errors?.start}
							/>

							<FormTimePicker
								error={Boolean(formik?.touched?.end && formik?.errors?.end)}
								label="Choose An End Time"
								onError={() => formik?.setErrors("end", "End Is Required")}
								value={formik?.values?.end}
								onChange={(value) => formik?.setFieldValue("end", value?._d)}
								onBlur={formik?.handleBlur("end")}
								helper={formik?.touched?.end && formik?.errors?.end}
							/>

							<>
								<FormTimePicker
									error={Boolean(formik?.touched?.break && formik?.errors?.break)}
									label="Set Break Time"
									onError={() =>
										formik?.setErrors("break", "Break Is a time format")
									}
									value={formik?.values?.break}
									onChange={(value) => formik.setFieldValue("break", value?._d)}
									onBlur={formik?.handleBlur("break")}
									helper={formik?.touched?.break && formik?.errors?.break}
								/>



								<FormTimePicker
									error={Boolean(
										formik?.touched?.breakEnd && formik?.errors?.breakEnd
									)}
									label="Set Break End Time"
									onError={() =>
										formik?.setErrors("breakEnd", "Break End Is a time format")
									}
									value={formik?.values?.breakEnd}
									onChange={(value) => formik.setFieldValue("breakEnd", value?._d)}
									onBlur={formik?.handleBlur("breakEnd")}
									helper={formik?.touched?.breakEnd && formik?.errors?.breakEnd}
								/>
							</>


							<>
								<FormTimePicker
									error={Boolean(
										formik?.touched?.postProd && formik?.errors?.postProd
									)}
									label="Set Post-Prod Time"
									onError={() =>
										formik.setErrors("postProd", "Post-Prod Is a time format")
									}
									value={formik.values.postProd}
									onChange={(value) =>
										formik?.setFieldValue("postProd", value?._d)
									}
									onBlur={formik?.handleBlur("postProd")}
									helper={formik?.touched?.postProd && formik?.errors?.postProd}
								/>
								<FormTimePicker
									error={Boolean(
										formik?.touched?.postProdEnd && formik?.errors?.postProdEnd
									)}
									label="Set Post-Prod End Time"
									onError={() =>
										formik.setErrors(
											"Post-Prod End",
											"Post-Prod Is a time format"
										)
									}
									value={formik?.values?.postProdEnd}
									onChange={(value) =>
										formik.setFieldValue("postProdEnd", value?._d)
									}
									onBlur={formik.handleBlur("postProdEnd")}
									helper={
										formik?.touched?.postProdEnd && formik?.errors?.postProdEnd
									}
								/>
							</>

							<>
								<FormTimePicker
									error={Boolean(
										formik?.touched?.prepStart && formik?.errors?.prepStart
									)}
									label="Set Preparation Start"
									onError={() =>
										formik.setErrors("prepStart", "Prep Is a time format")
									}
									value={formik?.values?.prepStart}
									onChange={(value) =>
										formik.setFieldValue("prepStart", value?._d)
									}
									onBlur={formik?.handleBlur("prepStart")}
									helper={formik?.touched?.prepStart && formik?.errors?.prepStart}
								/>
								<FormTimePicker
									error={Boolean(
										formik?.touched?.prepEnd && formik?.errors?.prepEnd
									)}
									label="Set Preparation End"
									onError={() =>
										formik.setErrors(
											"prepEnd",
											"Prep Is a time format"
										)
									}
									value={formik?.values?.prepEnd}
									onChange={(value) =>
										formik?.setFieldValue("prepEnd", value?._d)
									}
									onBlur={formik?.handleBlur("prepEnd")}
									helper={
										formik?.touched?.prepEnd && formik?.errors?.prepEnd
									}
								/>
							</>


						</LocalizationProvider>

						<FormTextField
							error={Boolean(formik?.touched?.expense && formik?.errors?.expense)}
							helperText={formik?.touched?.expense && formik?.errors?.expense}
							label="Extra Expense"
							onBlur={formik.handleBlur("expense")}
							onChange={formik.handleChange("expense")}
							value={formik?.values?.expense}
						/>

						<FormTextField
							error={Boolean(formik.touched.contact && formik.errors.contact)}
							helperText={formik.touched.contact && formik.errors.contact}
							label="Contact"
							onBlur={formik.handleBlur("contact")}
							onChange={formik.handleChange("contact")}
							value={formik?.values?.contact}
						/>

						<FormTextField
							error={Boolean(formik.touched.customer && formik.errors.customer)}
							helperText={formik.touched.customer && formik.errors.customer}
							label="Customer"
							onBlur={formik.handleBlur("customer")}
							onChange={formik.handleChange("customer")}
							value={formik?.values?.customer}
						/>

						<FormTextField
							error={Boolean(formik.touched.location && formik.errors.location)}
							helperText={formik.touched.location && formik.errors.location}
							label="Location"
							onBlur={formik.handleBlur("location")}
							onChange={formik.handleChange("location")}
							value={formik?.values?.location}
						/>


						<FormTextField
							multiline
							error={Boolean(formik.touched.remarks && formik.errors.remarks)}
							helperText={formik.touched.remarks && formik.errors.remarks}
							label="Remarks"
							onBlur={formik.handleBlur("remarks")}
							onChange={formik.handleChange("remarks")}
							value={formik?.values.remarks}
						/>

						<FormCheckbox
							error={Boolean(
								formik.touched.travelCost && formik.errors.travelCost
							)}
							helperText={formik.touched.travelCost && formik.errors.travelCost}
							label="Travel Cost"
							onBlur={formik.handleBlur("travelCost")}
							onChange={formik.handleChange("travelCost")}
							value={formik?.values?.travelCost}
						/>
						{isAdmin && <FormCheckbox
							error={Boolean(
								formik.touched.processed && formik.errors.processed
							)}
							helperText={formik.touched.processed && formik.errors.processed}
							label="Processed"
							onBlur={formik.handleBlur("processed")}
							onChange={formik.handleChange("processed")}
							value={formik?.values?.processed}
						/>}


						<FormButton disabled={formik.isSubmitting} text="Edit Report" />
					</form>
				</Container>
			</Box>
		</>
	);
};

export default EditReportScreen;
