import axios from "axios";
import { API_URL } from "../Services/config";

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/ld+json",
    "Content-Type": "application/json",
  },
});

export default apiClient;
