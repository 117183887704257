import React from "react";
import NavBar from "../Components/NavBar";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./LoginScreen";
import { useAppContext } from "../contexts/AppContext";
import EditReportScreen from "./ReportsScreen/EditReportScreen";
import AdminRoutes from "../Components/RoutesComponents/AdminRoutes";
import ProtectedRoutes from "../Components/RoutesComponents/ProtectedRoutes";
import ReportsScreen from "./ReportsScreen/ReportsScreen";
import ReportDetailsScreen from "./ReportsScreen/ReportDetailsScreen";
import NewReportScreen from "./ReportsScreen/NewReportScreen";
import AdminProjectsScreen from "./AdminScreens/AdminProjectsScreen";
import NewProjectScreen from "./AdminScreens/NewProjectScreen";
import ProjectDetailsScreen from "./AdminScreens/ProjectDetailsScreen";
import EditProjectScreen from "./AdminScreens/EditProjectScreen";
import AdminUsersScreen from "./AdminScreens/AdminUsersScreen";
import NewUserScreen from "./AdminScreens/NewUserScreen";
import routes from "../Services/routes";
import SupportScreen from "./Support/SupportScreen";

export default function MainApp() {
	const { isAutenticated } = useAppContext();
	return (
		<>
			{isAutenticated && <NavBar />}
			<Routes>
				<Route path={routes.login} element={<LoginScreen />} />
				<Route element={<ProtectedRoutes />}>
					<Route path={routes.reports} element={<ReportsScreen />} />
					<Route
						path={routes.reportDetails}
						element={<ReportDetailsScreen />}
					/>
					<Route path={routes.newReport} element={<NewReportScreen />} />
					<Route path={routes.editReport} element={<EditReportScreen />} />

				</Route>
				<Route element={<ProtectedRoutes />}>
					<Route path={routes.projects} element={<AdminProjectsScreen />} />
					<Route
						path={routes.projectDetails}
						element={<ProjectDetailsScreen />}
					/>
					<Route path={routes.newProject} element={<NewProjectScreen />} />
					<Route path={routes.editProject} element={<EditProjectScreen />} />

				</Route>
				<Route element={<ProtectedRoutes />}>
					<Route path={routes.support} element={<SupportScreen />} />
				</Route>
				<Route element={<AdminRoutes />}>
					<Route path={routes.users} element={<AdminUsersScreen />} />
					<Route path={routes.newUser} element={<NewUserScreen />} />
					{/* <Route path={routes.dashboard} element={<DashboardScreen />} /> */}
				</Route>
			</Routes>
		</>
	);
}
