import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";

export default function FormCheckbox({
  error,
  helperText,
  onChange,
  onBlur,
  label,
  value,
}) {
  return (
    <FormControl error={error} fullWidth sx={{ my: 1 }}>
      <FormLabel>{label}</FormLabel>
      <Checkbox onBlur={onBlur} checked={value} onChange={onChange} />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
