import apiClient from "./client";

const key = "/projects";

const findAll = async () => {
	const response = await apiClient.get(key);
	return response.data["hydra:member"];
};

const deleteItem = async (id) => {
	return await apiClient.delete(key + "/" + id);
};

const createProject = async (values) => {
	return await apiClient.post(key, {
		code: values.code,
		projectName: values.projectName,
		status: "In Process"
	});
};
const editProject = async (values, id) => {
	return await apiClient.put(key + "/" + id, {
		code: values.code,
		projectName: values.projectName,
		status: values.status,
	});
};

const handleProjectStatusFromReport = async (projectId, doneStatus) => {
	if (projectId) return await apiClient.put(key + "/" + projectId, {
		status: doneStatus === true ? "Done" : "In Process",
	});
	return
};

const projectsApi = {
	findAll,
	deleteItem,
	createProject,
	editProject,
	handleProjectStatusFromReport
}

export default projectsApi;
