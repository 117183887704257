import React from "react";
import { TextField, FormControl } from "@mui/material";

export default function FormTextField({
  error,
  helperText,
  label,
  onBlur,
  onChange,
  value,
  multiline = false,
  type,
  ...props
}) {
  return (
    <FormControl fullWidth sx={{ my: 1 }}>
      <TextField
        {...props}
        multiline={multiline}
        error={error}
        fullWidth
        helperText={helperText}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        variant="outlined"
        type={type}
      />
    </FormControl>
  );
}
