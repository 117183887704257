import React from 'react'
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { FormHelperText } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

function SimpleDialog(props) {
	const { onClose, selectedValue, open, users, handleSelect, user } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (user) => {
		selectedValue.includes(user) ? selectedValue.splice(selectedValue.indexOf(user), 1) : selectedValue.push(user)
		let usersIds = selectedValue.map(u => u.id)
		handleSelect("user", usersIds);
	};

	let usersMinusMe = users?.filter(u => u?.id !== user?.id)

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>Select Teammates</DialogTitle>
			<FormControl sx={{ my: 1 }} fullWidth>
				<List sx={{ pt: 0 }}>
					{usersMinusMe.map((user) => (
						<ListItem key={user.id} disableGutters>
							<ListItemButton onClick={() => handleListItemClick(user)}>
								<ListItemAvatar>
									{selectedValue.includes(user)
										?
										<Avatar>
											<CheckIcon />
										</Avatar>
										:
										<Avatar src={`../assets/images/${user?.avatar}`} />}
								</ListItemAvatar>
								<ListItemText primary={user.firstname} />
							</ListItemButton>
						</ListItem>
					))}

				</List>
			</FormControl>
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedValue: PropTypes.array.isRequired,
};

export default function MultipleSelectUsers({ users, handleSelect, user, helper }) {
	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState([]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};

	return (
		<Box>
			<Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
				<Button variant="outlined" onClick={handleClickOpen} endIcon={<PersonIcon />}>
					Add Users
				</Button>
				<FormHelperText sx={{ ml: 2 }}>{helper}</FormHelperText>

			</Box>

			<Typography sx={{ mx: 1 }} variant="subtitle1" component="div">
				{selectedValue?.map(value => <Chip sx={{ mx: 0.5 }} key={value?.id} label={value?.tag} />)}
			</Typography>
			<SimpleDialog
				user={user}
				users={users}
				selectedValue={selectedValue}
				open={open}
				onClose={handleClose}
				handleSelect={handleSelect}
			/>

		</Box>
	);
}
