import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ProjectDetailsTable({ project }) {

  return (
    <TableContainer sx={{ my: 5 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Code</TableCell>
            <TableCell align="center">Project name</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{
            backgroundColor: (project?.status === "Done" ? "#95DB9A" : project?.status === "In Process" ? "#938DE2" : project?.status === "Pending" ? "#ED975A" : "#AFB8C0"),
          }}>
            <TableCell align="center">{project?.id}</TableCell>
            <TableCell align="center">{project?.code}</TableCell>
            <TableCell align="center">{project?.project_name}</TableCell>
            <TableCell align="center">
              {project?.status === "Pending" && "⏸️ Pending"}
              {project?.status === "In Process" && "🚧 In Process"}
              {project?.status === "Done" && "✔️ Done"}
              {project?.status === "Cancelled" && "🛑 Cancelled"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
