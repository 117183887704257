import React from 'react'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import GetAppIcon from '@mui/icons-material/GetApp';
export const downloadsAssets = [
	{
		mainTitle: 'Editing Assets',
		files: [
			{
				title: 'LA71/218 Template for Premiere Pro (.prproj)',
				fileName: 'LA71_218_1080p.prproj',
				icon: <DocumentScannerIcon />
			},
			{
				title: 'BAU1 Template for Premiere Pro (.prproj)',
				fileName: 'Bau1_720p.prproj',
				icon: <DocumentScannerIcon />
			},
			{
				title: 'Closing Video 720p (.mp4)',
				fileName: 'closing_video_720p.mp4',
				icon: <VideoFileIcon />
			},
			{
				title: 'Closing Video 1080p (.mp4)',
				fileName: 'closing_video_1080p.mp4',
				icon: <VideoFileIcon />
			},
			{
				title: 'Webcast Backgrounds (.pptx)',
				fileName: 'Webcast_Backgrounds.pptx',
				icon: <SlideshowIcon />
			},
		]
	},
	{
		mainTitle: 'Docs',
		files: [
			{
				title: 'Pearl_2',
				fileName: 'Pearl_2_UserGuide.pdf',
				icon: <PictureAsPdfIcon />
			},
			{
				title: 'AVMATRIX Video Switcher (HVS0402U)',
				fileName: 'AVMATRIX_HVS0402U_video_switcher.pdf',
				icon: <PictureAsPdfIcon />
			},
			{
				title: 'AVMATRIX Controller (PKC2000)',
				fileName: 'AVMATRIX_PKC2000_PTZ_Controller.pdf',
				icon: <PictureAsPdfIcon />
			},
			{
				title: 'AVMATRIX PTZ camera',
				fileName: 'AVMATRIX_PTZ_camera.pdf',
				icon: <PictureAsPdfIcon />
			},
			{
				title: 'AVMATRIX Splitter/Converter (SD2080)',
				fileName: 'AVMATRIX_SD2080_splitter_converter.pdf',
				icon: <PictureAsPdfIcon />
			},
			{
				title: 'AVMATRIX SDI Streaming Encoder (SE1117)',
				fileName: 'SE1117_SDI_Streaming_Encoder.pdf',
				icon: <PictureAsPdfIcon />
			},
		]
	},
	{
		mainTitle: 'Softwares',
		files: [
			{
				title: 'Shutter (Windows Event Handler)',
				fileName: 'shutter-4.5.exe',
				icon: <GetAppIcon />
			},
			{
				title: 'NetScan (IP scan)',
				fileName: 'netscan_setup.exe',
				icon: <GetAppIcon />
			},
		]
	}
]
