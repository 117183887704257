import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function FormTitle({ title, action }) {
  return (
    <Box sx={{ my: 3, display: "flex", justifyContent: "flex-start" }}>
      <Button onClick={action}>
        <ArrowBackIosIcon />
      </Button>

      <Typography color="textPrimary" variant="h6">
        {title}
      </Typography>
    </Box>
  );
}
