import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import projectsApi from "../../api/projectsApi";
import ProjectsTable from "../../Components/ProjectsComponents/ProjectsTable";
import AppFab from "../../Components/AppButton.jsx/AppFab";
import SearchBar from "../../Components/SearchBar";
import DeleteAlert from "../../Components/DialogAlert/DeleteAlert";
import LoadingTable from "../../Components/LoadingTable";
import routes from "../../Services/routes";

export default function AdminProjectsScreen() {
	const {
		projects,
		setProjects,
		getProjects,
		isAlertOpen,
		setIsAlertOpen,
		search,
		setSearch,
		setIsLoading,
		isLoading,
	} = useAppContext();
	const [project, setProject] = useState(null);
	const [page, setPage] = useState(1);
	const navigate = useNavigate();

	const handleClickOpen = (project) => {
		setProject(project);
		setIsAlertOpen(true);
	};

	const handleClose = () => {
		setProject(null);
		setIsAlertOpen(false);
	};

	const closeAndNotify = (message) => {
		toast(message, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "dark",
		});
		handleClose();
	};

	const handleProjectDelete = async () => {
		let originalProjects = [...projects];
		setProjects(projects.filter((item) => item.id !== project.id));
		try {
			await projectsApi.deleteItem(project.id);
		} catch (error) {
			setProjects(originalProjects);
			toast.error("Something went wrong, try again.", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			console.log("EROOR DELETE PROJECT", error);
		} finally {
			closeAndNotify("Project Deleted");
		}
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};
	const handleGetProjects = async () => {
		setIsLoading(true);
		try {
			await getProjects();
		} catch (error) {
			console.log("getreportserror", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		handleGetProjects();
	}, []);

	const searchedProjects = projects.filter(
		(p) =>
			p.status?.toLowerCase().includes(search.toString().toLowerCase()) ||
			p.code?.toLowerCase().includes(search.toString().toLowerCase()) ||
			p.project_name?.toLowerCase().includes(search.toString().toLowerCase())
	);
	const handleSearch = (event) => {
		const value = event.currentTarget.value;
		setPage(1);
		setSearch(value);
	};

	const itemsPerPage = 30;
	const pageCount = Math.ceil(searchedProjects?.length / itemsPerPage);
	const start = page * itemsPerPage - itemsPerPage;

	const paginatedProjects = searchedProjects?.slice(
		start,
		start + itemsPerPage
	);

	const handleChipsFilter = (status) => {
		setSearch("");
		setSearch(status);
	};
	return (
		<>
			<ToastContainer />
			<AppFab
				onClick={() => navigate(routes.newProject)}
				into={projects?.length >= 0 ? true : false}
				color="secondary"
			/>
			<SearchBar
				onChange={handleSearch}
				value={search}
				handleChipsFilter={handleChipsFilter}
			/>
			{pageCount > 1 && (
				<Pagination
					sx={{ p: 2 }}
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					color="primary"
				/>
			)}

			{isLoading ? (
				<LoadingTable />
			) : (
				<ProjectsTable data={paginatedProjects} openDialog={handleClickOpen} />
			)}

			{pageCount > 1 && (
				<Pagination
					sx={{ p: 2 }}
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					color="primary"
				/>
			)}

			<DeleteAlert
				open={isAlertOpen}
				title="Delete Project"
				data={project}
				handleDelete={handleProjectDelete}
				clickClose={handleClose}
			/>
		</>
	);
}
