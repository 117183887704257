import React from "react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppContext from "./contexts/AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MainApp from "./Screens/MainApp";


export default function App() {
	return (
		<Router>
			<AppContext>
				<CssBaseline />
				<MainApp />
			</AppContext>
		</Router>
	);
}

// const container = document.getElementById("app");
// const root = createRoot(container);
// root.render(<App tab="home" />);
