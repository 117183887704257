import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Image from "mui-image";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import Avatar from "@mui/material/Avatar";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import AvatarGroup from '@mui/material/AvatarGroup';
import routes from "../Services/routes";
import logo from "../assets/images/logoZeller.png";

const drawerWidth = 240;

function NavBar(props) {
	const { user, isAdmin, logout } = useAppContext();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const navigate = useNavigate();
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const navItems = isAdmin
		? [
			{ title: "Reports", to: routes.reports },
			{ title: "Projects", to: routes.projects },
			{ title: "Users", to: routes.users },
			{ title: "Support", to: routes.support },
		]
		: [
			{ title: "Reports", to: routes.reports },
			{ title: "Projects", to: routes.projects },
			{ title: "Support", to: routes.support },
		];
	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
			<Box component={"div"} sx={{ display: "flex", justifyContent: "center" }}>
				<Image
					src={logo}
					height={"50px"}
					width={"50px"}
				/>
			</Box>
			<Divider />
			<List>
				{navItems.map((item) => (
					<ListItem key={item.title} disablePadding>
						<ListItemButton
							onClick={() => navigate(item.to)}
						>
							<ListItemText primary={item.title} />
						</ListItemButton>
					</ListItem>
				))}
				<ListItem disablePadding>
					<ListItemButton
						onClick={() => {
							logout();
						}}
					>
						<ListItemText primary="LOGOUT" />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			right: -3,
			top: 35,
			border: `1px solid ${theme.palette.background.paper}`,
			padding: '0 4px',
		},
	}));

	return (
		<Box sx={{ display: "flex" }}>
			<AppBar
				sx={{ backgroundColor: isAdmin ? "#445E74" : "#547FC9" }}
				component="nav"
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}
					>
						<AvatarGroup>
							<Avatar
								sx={{ bgcolor: "#ffff", width: 24, height: 24 }}
								src={logo}
							/>
							<Avatar
								sx={{ bgcolor: "#ffff", width: 36, height: 36 }}
								src={`../assets/images/${user?.avatar}`}
							/>
						</AvatarGroup>
					</IconButton>
					<Box
						component="div"
						sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
					>
						<StyledBadge badgeContent={user?.tag} color="primary">
							<AvatarGroup>
								<Avatar
									sx={{ bgcolor: "#ffff", width: 48, height: 48 }}
									src={logo}
								/>
								<Avatar
									sx={{ bgcolor: "#ffff", width: 56, height: 56 }}
									src={`../assets/images/${user?.avatar}`}
								/>
							</AvatarGroup>
						</StyledBadge>
					</Box>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						{navItems.map((item) => (
							<Button
								key={item.title}
								onClick={() => navigate(item.to)}
								sx={{ color: "#fff" }}
							>
								{item.title}
							</Button>
						))}
						<Button
							onClick={() => {
								logout();
							}}
							sx={{ color: "#fff" }}
						>
							LOGOUT
						</Button>
					</Box>
				</Toolbar>
			</AppBar>
			<Box component="nav">
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>
			<Toolbar />
		</Box>
	);
}


export default NavBar;
