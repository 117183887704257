import React from "react";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

export default function AppFab({ onClick, color }) {
  return (
    <Fab
      onClick={onClick}
      color={color}
      aria-label="add"
      sx={{ position: "fixed", right: 45, bottom: 45 }}
    >
      <AddIcon />
    </Fab>
  );
}
