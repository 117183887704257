import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIconButton from "../AppButton.jsx/DeleteIconButton";
import Chip from "@mui/material/Chip";
import { Avatar } from "@mui/material";

export default function UsersTable({ users, openDialog, freelancers }) {

  return (
    <TableContainer component={Paper} sx={{ py: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Tag</TableCell>
            <TableCell align="left">Full Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">Reports</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: "lightgray",
                  opacity: [0.9, 0.8, 0.7],
                  cursor: "pointer",
                },
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell align="left"><Chip avatar={user?.avatar ? <Avatar alt="Avatar" src={`/build/images/${user?.avatar}`} /> : undefined} sx={{ mx: 0.5 }} key={user?.id} label={user?.tag} /></TableCell>
              <TableCell align="left"><Chip sx={{ m: 0.5 }} key={user.id} label={user.firstname + " " + user.lastname} /></TableCell>
              <TableCell align="left">{user?.email}</TableCell>
              <TableCell align="left">{user?.phone}</TableCell>
              <TableCell align="left">{JSON.stringify(user?.roles)}</TableCell>
              <TableCell align="left">{user?.reports?.length}</TableCell>
              <TableCell align="left">
                <DeleteIconButton handleDelete={() => openDialog(user)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


