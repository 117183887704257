import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import Chip from "@mui/material/Chip";
import routes from "../../Services/routes";

export default function ProjectDetailsReportsTable({ project }) {
	const navigate = useNavigate();

	return (
		<>
			<Typography sx={{ my: 2 }}>Reports</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="center">ID</TableCell>
							<TableCell align="center">Event Date</TableCell>
							<TableCell align="center">Made By</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{project?.project_reports?.map((report) => (
							<TableRow
								key={report.id}
								sx={{
									"&:last-child td, &:last-child th": { border: 0 },
								}}
							>
								<TableCell align="center">{report?.id}</TableCell>
								<TableCell align="center">
									{moment(report?.date).format("DD MM YY")}
								</TableCell>
								<TableCell align="center">{report?.user?.map(u => <Chip sx={{ m: 0.5 }} key={u.id} label={u.firstname + " " + u.lastname} />)}</TableCell>
								<TableCell align="center">
									<IconButton
										sx={{ color: "blue" }}
										aria-label="edit"
										onClick={() =>
											navigate(routes.reportDetails, { state: report })
										}
									>
										<VisibilityIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
