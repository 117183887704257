import React from "react";
import {
	FormControl,
	FormHelperText,
	Autocomplete,
	TextField,
} from "@mui/material";

export default function FormSelect({
	error,
	label,
	onChange,
	data,
	helper,
	value,
}) {

	return (
		<FormControl error={error} fullWidth sx={{ my: 1 }}>
			<Autocomplete
				id={`${label}-Selection`}
				options={data || []}
				getOptionLabel={(option) => option ?
					`${option?.code} - ${option?.project_name} ` : 'Choose or Search Project'
				}
				value={value}
				onChange={(event, newValue) => {
					onChange(newValue)
				}}
				renderInput={(params) => (
					<TextField {...params} />
				)}
			/>
			<FormHelperText>{helper}</FormHelperText>
		</FormControl>
	);
}
